import axios from "axios"
import { ACTIVE_ETA_PWA_URL, ACTIVE_SERVICE_URL } from "../utils/environment"

const config = {
    params: {
        etaPwaUrl: ACTIVE_ETA_PWA_URL
    }
}

export const CORE_ACTION_GET_ADMIN_CONFIGURATION = async (callback, errorCallback) => {
    axios.get(`${ACTIVE_SERVICE_URL}/core/traces/adminConfiguration`, config)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_POST_MARK_ETA_AS_READ = async (body, callback, errorCallback) => {
    axios.post(`${ACTIVE_SERVICE_URL}/core/traces/readETA`, body, config)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_GET_ETA_DATA = async (queryString, callback, errorCallback) => {
    axios.get(`${ACTIVE_SERVICE_URL}/core/eta/${config.params.etaPwaUrl}|${queryString}`)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_GET_SIGN_UP_WITH_KEY = async (queryString, callback, errorCallback) => {
    axios.get(`${ACTIVE_SERVICE_URL}/core/eta/signUp/${queryString}/${ACTIVE_ETA_PWA_URL}`)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_POST_SIGN_UP_EMAIL_CHECK = async (registrationKey, email, callback, errorCallback) => {
    const body = {
        registration_key: registrationKey,
        email: email
    }

    axios.post(`${ACTIVE_SERVICE_URL}/core/eta/signUp/otp/generate/${ACTIVE_ETA_PWA_URL}`, body, config)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_POST_SIGN_UP_SEND_ACTIVATION_EMAIL = async (registrationKey, email, notificationPayload, callback, errorCallback) => {
    const body = {
        registration_key: registrationKey,
        email: email,
        etaEmailSetting: notificationPayload
    }

    axios.post(`${ACTIVE_SERVICE_URL}/core/eta/signUp/otp/activationEmail/${ACTIVE_ETA_PWA_URL}`, body, config)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_POST_SIGN_UP_EMAIL_VALIDATION = async (registrationKey, outletId, hashedOtp, email, callback, errorCallback) => {
    const body = {
        registration_key: registrationKey,
        outlet_id: outletId,
        otp: hashedOtp,
        email: email
    }

    axios.post(`${ACTIVE_SERVICE_URL}/core/eta/signUp/otp/validate/${ACTIVE_ETA_PWA_URL}`, body, config)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_GET_RETRIEVE_OUTLET_EMAIL_ADDRESSES = async (data, queryString, callback, errorCallback) => {
    if (data === null) {
        axios.get(`${ACTIVE_SERVICE_URL}/core/traces/outletEmails/${queryString}/null`,)
            .then(res => callback(res))
            .catch(err => errorCallback(err))
    } else {
        axios.get(`${ACTIVE_SERVICE_URL}/core/traces/outletEmails/${queryString}/${data.userRole}`,)
            .then(res => callback(res))
            .catch(err => errorCallback(err))
    }
}

export const CORE_ACTION_GET_RETRIEVE_OUTLET_EMAIL_ADDRESSES_WITH_OUTLETID = async (data, outletId, callback, errorCallback) => {
    if (data === null) {
        axios.get(`${ACTIVE_SERVICE_URL}/core/traces/outletEmailsByOutletId/${outletId}/null`,)
            .then(res => callback(res))
            .catch(err => errorCallback(err))
    } else {
        axios.get(`${ACTIVE_SERVICE_URL}/core/traces/outletEmailsByOutletId/${outletId}/${data.userRole}`,)
            .then(res => callback(res))
            .catch(err => errorCallback(err))
    }
}

export const CORE_ACTION_POST_CREATE_OUTLET_EMAIL_ADDRESSES = async (userRole, sendEmail, data, callback, errorCallback) => {
    axios.post(`${ACTIVE_SERVICE_URL}/core/traces/outletEmails/${ACTIVE_ETA_PWA_URL}/${userRole}/${sendEmail}`, data)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_EDIT_OUTLET_EMAIL_ADDRESSES = async (userRole, data, callback, errorCallback) => {
    axios.put(`${ACTIVE_SERVICE_URL}/core/traces/outletEmails/${userRole}`, data)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_DELETE_OUTLET_EMAIL_ADDRESSES = async (userRole, sendData, callback, errorCallback) => {
    axios.delete(`${ACTIVE_SERVICE_URL}/core/traces/outletEmails/${sendData.id}/${sendData.loggedinEmail}/${userRole}`)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_POST_CREATE_MULTIPLE_OUTLET_EMAIL_ADDRESSES = async (userRole, sendEmail, data, callback, errorCallback) => {
    axios.post(`${ACTIVE_SERVICE_URL}/core/traces/multipleOutletEmails/${ACTIVE_ETA_PWA_URL}/${userRole}/${sendEmail}`, data)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_POST_REGISTER_SELECTED_OUTLETS = async (data, callback, errorCallback) => {
    axios.post(`${ACTIVE_SERVICE_URL}/core/traces/signUp/registerSelectedOutlets`, data)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_GET_CONNECTED_OUTLETS_BY_OUTLET_ID_AND_CUSTOMER_NUMBER = async (outletId, callback, errorCallback) => {
    axios.get(`${ACTIVE_SERVICE_URL}/core/traces/connectedOutletsByCustomerNumber/${outletId}`)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

// NOTE: set size to 0 get all Data
export const CORE_ACTION_GET_CONNECTED_OUTLETS_BY_OUTLET_ID = async (outletId, page, size, { loggedInEmail, searchString }, callback, errorCallback) => {
    const configuration = {
        params: {
            searchString: searchString,
            loggedInEmail: loggedInEmail
        }
    }
    axios.get(`${ACTIVE_SERVICE_URL}/core/traces/connectedOutlets/${outletId}/${page}/${size}`, configuration)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_GET_OUTLET_EMAILS_BY_OUTLET_ID_AND_ROLE = async (outletId, role, callback, errorCallback) => {
    axios.get(`${ACTIVE_SERVICE_URL}/core/traces/check/${outletId}/${role}`)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_GET_OUTLET_AND_MAPPED_OUTLET_EMAILS_BY_OUTLETID = async (outletId, callback, errorCallback) => {
    const configuration = {
        params: {
            outletId: outletId
        }
    }
    axios.get(`${ACTIVE_SERVICE_URL}/core/traces/test/outlet`, configuration)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_POST_SIGN_IN = async (email, callback, errorCallback) => {
    const body = {
        email: email,
        etaPwaUrl: ACTIVE_ETA_PWA_URL
    }

    axios.post(`${ACTIVE_SERVICE_URL}/core/eta/signIn`, body)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_POST_SIGN_IN_VALIDATION = async (email, otp, callback, errorCallback) => {
    const body = {
        otp: otp,
        email: email
    }

    axios.post(`${ACTIVE_SERVICE_URL}/core/eta/signIn/otp/validate`, body, config)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_POST_SIGN_IN_INVITATION_VALIDATION = async (email, outletId, otp, callback, errorCallback) => {
    const body = {
        otp: otp,
        outlet_id: outletId,
        email: email
    }

    axios.post(`${ACTIVE_SERVICE_URL}/core/eta/signIn/otp/validate/inv/${ACTIVE_ETA_PWA_URL}`, body, config)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}

export const CORE_ACTION_POST_ADD_NEW_EMAIL_CLICK_REPORT = async (etaLink, data, completeLink, callback, errorCallback) => {
    const body = {
        etaLink: etaLink,
        completeLink: completeLink,
        encodedData: data,
    }

    axios.post(`${ACTIVE_SERVICE_URL}/core/report/email/${ACTIVE_ETA_PWA_URL}`, body, config)
        .then(res => callback(res))
        .catch(err => errorCallback(err))
}