import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CORE_ACTION_POST_ADD_NEW_EMAIL_CLICK_REPORT } from "../Actions/CoreActions";

const DeeplinkRedirect = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()

    useEffect(() => {
        let deeplink = searchParams.get("dl")
        let data = searchParams.get("d")

        if (!deeplink || !data) {
            // redirect to {url}
            // console.log(`redirect to ${window.location.origin}`)
            navigate(`/`, { replace: true })
        }
        else {
            // console.group("REDIRECT PAGE")
            // console.log("Deeplink", deeplink)
            // console.log("Data", data)
            // console.log(window.location)
            // console.log(window.location.origin)
            // console.log(`redirect to ${window.location.origin}/${deeplink}`)
            // console.groupEnd()

            CORE_ACTION_POST_ADD_NEW_EMAIL_CLICK_REPORT(
                deeplink,
                data,
                window.location.href,
                res => {

                },
                err => {

                })

            navigate(`/${deeplink}`, { replace: true })
        }
    }, [])
}

export default DeeplinkRedirect